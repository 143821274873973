<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <h2>{{ getCase.FileNumber }}</h2>
    <hr>
    <b-row>
      <b-col md="9">
        <h2>Case Update Email</h2>
        <div class="white-bg-container">
          <b-row>
            <b-col md="7">
              <b-form-group
                label="FROM:"
                label-for="from"
                label-cols-sm="3"
              >
                <b-form-input
                  id="from"
                  v-model="From"
                  placeholder=""
                  name="from"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="TO:"
                label-for="to"
                label-cols-sm="3"
              >
                <b-form-input
                  id="to"
                  v-model="To"
                  placeholder=""
                  name="to"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="CC:"
                label-for="cc"
                label-cols-sm="3"
              >
                <b-form-input
                  id="cc"
                  v-model="Cc"
                  placeholder=""
                  name="cc"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="BCC:"
                label-for="bcc"
                label-cols-sm="3"
              >
                <b-form-input
                  id="bcc"
                  v-model="Bcc"
                  placeholder=""
                  name="bcc"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="7">
              <b-form-group
                label="SUBJECT:"
                label-for="subject"
                label-cols-sm="3"
              >
                <b-form-input
                  id="subject"
                  v-model="Subject"
                  placeholder=""
                  name="subject"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="BODY:"
                label-for="body"
              >
                <div
                  id="body"
                  class="collapse-textarea"
                  :class="{expanded: !isExpanded}"
                >
                  <textarea
                    v-model="Body"
                    class="expandable-textarea"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                    contentEditable
                  />
                  <div @click="isExpanded = !isExpanded">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpanded ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="sendEmail()"
        >
          Send Email
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      From: "",
      To: "",
      Cc: "",
      Bcc: "",
      Subject: "",
      Body: "",

      caseObj: {},
      templateData: {},
      userObj: {},
      JournalEntryID: "",

      isExpanded: false,
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.caseObj.FileNumber,
        },
      ]
    },
  },
  watch: {},
  async created() {
    try {
      this.getUserInfo();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getCase() {
      const CaseID = this.$route.params.caseId;
      this.JournalEntryID = this.$route.params.journalEntryID;
      apiService
          .get("case/" + CaseID + "/detail")
          .then(res => {
            this.caseObj = res.data;
            this.getTemplateData(this.JournalEntryID)
          })
    },
    getTemplateData(JournalEntryID) {
      apiService
          .get("template/journal-email-template/parse-with/entry/" + JournalEntryID)
          .then(res => {
            this.templateData = res.data.Compiled;
            this.fillData();
          })
    },
    getUserInfo() {
      apiService
          .get("auth/userinfo")
          .then(res => {
            this.userObj = res.data;
            this.getCase();
          })
    },
    fillData() {
      this.From = this.templateData.From;
      this.To = this.templateData.To;
      this.Cc = this.templateData.Cc;
      this.Bcc = this.templateData.Bcc;
      this.Subject = this.templateData.Subject;
      this.Body = this.templateData.BodySection1 + this.templateData.BodySection2 + this.templateData.BodySection3;
    },
    isEmpty(val) {
      return val ? val : "";
    },
    resetForm() {
      this.$router.push('/cases/' + this.caseObj.CaseID + "/detail");
    },
    sendEmail() {
      const postData = {
        "From": this.From,
        "To": this.To,
        "CC": this.Cc,
        "BCC": this.Bcc,
        "Subject": this.Subject,
        "Body": this.Body,
      }
      apiService.post("journal/entries/" + this.JournalEntryID + "/sendupdate", postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Email sent!');
          this.$router.push('/cases/' + this.caseObj.CaseID + "/detail");
        }
      })
    },
  }
}
</script>

<style scoped>

</style>